import React from 'react';

import spinnerImg from 'img/common/spinner.svg';

const SpinnerPlaceholder = (props) => {
  const { layout, text } = props;
  return (
    <>
      <div className={`c-placeholder c-placeholder--${layout}`}>
        <div className="c-placeholder__icon">
          <div className="c-placeholder__img">
            <img
              className="e-img e-img--contain"
              src={spinnerImg}
              alt="loading"
            />
          </div>
          <span className="c-placeholder__text">{text}...</span>
        </div>
      </div>
    </>
  );
};

export default SpinnerPlaceholder;
